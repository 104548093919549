import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import type { ReactElement, ReactNode } from "react";
import { niceOverlayProps } from "../../utils/niceOverlayProps.tsx";
import { WModalOverlay } from "./WModal.tsx";

/** Show a modal with the given React element as its body. */
export function showModal(body: ReactElement) {
    discardPromise(waitModal(body));
}

/** Wait for a modal with the given React element as its body to be closed. */
export async function waitModal(body: ReactElement) {
    return await NiceModal.show(ModalWrapper, { children: body });
}

const ModalWrapper = NiceModal.create(function ModalWrapper({ children }: { children: ReactNode }) {
    const modal = useModal();
    return <WModalOverlay {...niceOverlayProps(modal)}>{children}</WModalOverlay>;
});

/**
 * Call {@link NiceModal.show}`(RegisteredModal, { ... props })` to display the registered modal.
 */
export function registerModal<TProps extends {}>(Component: React.ComponentType<TProps>) {
    return NiceModal.create(function RegisterModalWrapper(props: TProps) {
        const modal = useModal();
        return (
            <WModalOverlay {...niceOverlayProps(modal)}>
                <Component {...props} />
            </WModalOverlay>
        );
    });
}
